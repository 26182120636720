.landing-container{
    height: 100%;
    position: relative;
    
    .info-container{
        height: calc(100% - 64px);
        width: 100%;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 30px 0px 30px;
    }
    
}

